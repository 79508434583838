import {css} from 'lit';

export default css`
  :host {
    background-color: var(--identity-lowVisibility);
    padding: var(--gap);
    display: block;
  }


  form {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    gap: 30px;
  }

  p, h2 {
    padding: 0;
    margin: 0;
  }

  h2 {
    font-size: 2.4rem;
    line-height: 32px;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: calc(var(--gap) / 2); 
  }

  ul li {
    display: flex;
    align-items: center;
    gap: calc(var(--gap) / 2);
  }

  .check {
    background: var(--regpromotool-check) no-repeat 50% 0 / contain;
    display: inline-block;
    width: 1.9rem;
    height: 2rem;
  }


  .combo {
    background-color: var(--form-default-background);
    border: 1px solid var(--outline-lowVisibility);
    padding: 0 1rem 0 0;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .combo input {
    flex: 1;
    border-color: transparent;
    padding: 1rem 0 1rem 1rem;
    margin: 0;
  }

  .combo span {
    color: var(--text-lowVisibility);
  }

  .combo input, .combo span {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  button {
    transform: translateY(-10px);
    border: 0;
    background-color: var(--button-primary-default-background);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    border-radius: 0.4rem;
  }

  button span {
    display: inline-block;
    color: var(--button-primary-default-label);
    font-family: var(--font-family-sans-serif), sans-serif;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;
