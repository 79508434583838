import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import styles from './regpromotool.styles';
import {resetStyles} from '../../ts/css/resets.styles';
import Tracking2 from '../../ts/services/tracking2';
import generalStyles from '../../ts/css/general.styles';
import elementsStyles from '../../ts/css/elements.styles';

type tenants = 'gmx.net' | 'gmx.at' | 'gmx.ch' | 'web.de';

@customElement('ui-regpromotool')
export class RegPromoTool extends LitElement {
  static readonly styles = [resetStyles, generalStyles, elementsStyles, styles];

  domainMapper: Record<tenants, string> = {
    'gmx.at': 'https://registrierung.gmx.net/#.pc_page.homepage_at.promotool.registrierung_at',
    'gmx.ch': 'https://registrierung.gmx.net/#.pc_page.homepage_ch.promotool.registrierung_ch',
    'gmx.net': 'https://registrierung.gmx.net/#.pc_page.homepage.promotool.registrierung',
    'web.de': 'https://registrierung.web.de/#.pc_page.homepage.promotool.registrierung'
  };

  private _domain: tenants = 'gmx.net';

  @property() set domain(domain: tenants) {
    const isValidTenant = domain in this.domainMapper;
    if (isValidTenant) {
      this._domain = domain;
    }
  }

  get domain() {
    return this._domain;
  }

  connectedCallback() {
    super.connectedCallback();
    Tracking2.bindTrackingExposeHandler(this);
  }

  get componentPath() {
    return this.getAttribute('data-component-path');
  }

  get formActionUrl() {
    return this.domainMapper[this.domain];
  }

  onInvalidHandler(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.setCustomValidity('Bitte Wunsch-E-Mail-Adresse eingeben.');
  }

  onInputHandler(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.setCustomValidity('');
  }

  render() {
    return html`
      <form action="${this.formActionUrl}" method="get">
       <ul>
          <li><span class='check'></span>Sicher & kostenfrei mailen</li>
          <li><span class='check'></span>E-Mail-Konto aus Deutschland</li>
        </ul>
        <label>
          <h2>Wunsch-E-Mail-Adresse</h2>
          <div class="combo">
            <input type="text" maxlength="40"  @invalid="${this.onInvalidHandler}"  @input="${this.onInputHandler}" name="emailAlias" required/>
            <span>@${this.domain}</span>
          </div>
        </label>
        <input type="hidden" name="emailDomain" value="${this.domain}"/>
        ${this.domain == 'gmx.at' ? html`<input type="hidden" name="defaultCountry" value="AT" />` : null}
        ${this.domain == 'gmx.ch' ? html`<input type="hidden" name="defaultCountry" value="CH" />` : null}
        <button class="primary" type="submit" @click=${this.trackClick}><span>Registrierung starten</span></button>
      </form>
    `;
  }

  trackClick(ev: Event) {
    if (this.componentPath) {
      Tracking2.callTealiumLink({
        businessEventType: 'userAction',
        componentPath: `${this.componentPath}.button`,
        componentLabel: (ev.target as HTMLElement).innerText,
        elementTagName: 'button',
        eventType: 'mousedown'
      });
    }
  }
}
